import { LandingPage, PageIdType } from 'generated-graphql';
import { initializeApollo } from 'lib/apolloClient';
import BlockMix from '@/components/BlockMix/BlockMix';
import { getEntityBlocks } from 'gql/common/blocks';
import { getGlobalData } from 'lib/global';
import styles from "./ThisMachine.module.scss"
import appConfig from '@/app.config';
import { MainParagraph, SecondaryTitle } from '@/componentsWP/StyledComponentsLib/StyledComponentsLib';
import dynamic from 'next/dynamic';
import Team from '@/componentsWP/Team/Team';
import Offices from '@/componentsWP/Offices/Offices';
import { useState } from 'react';
import Link from 'next/link';
import { GetServerSideProps } from 'next';
import SEO from '@/components/SEO/SEO';
import Head from 'next/head';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });


export default function Home({
  pageBlocks,
}: {
  pageBlocks?: LandingPage;
}) {
  const [ready, setReady] = useState(false);
  return (

    <div className={styles.thisMachine}>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
      </Head>
      <SEO
        title="We work with brands that are ready to show up - This Machine"
        description="Consumers and business customers are on an ever-evolving journey, forging their own paths and doing things their way. For brands, showing up along that journey — especially in the moments that matter — is critical. This means taking an active role in helping people reach their dreams, build their plans, and fulfill their needs. Our people, ideas, tools and grit help brands show up."
        og={{
          siteName: 'This Machine',
          image: `https://thismachine.agency/thismachine-cover.png`,
        }}
      />
      <div className={styles.header} >
        {/* <div className={styles.poster} style={{ display: ready ? "block" : "block", paddingBottom: "83%", backgroundImage: "url(/images/tm-header-poster.png)", backgroundSize: "cover" }} /> */}

        <div className={styles.player} >
          <ReactPlayer
            className='player-tm'
            url='/videos/tm-header-home.mp4'
            playing={true}
            width='100%'
            height='75vh'
            muted
            controls={false}
            loop
            playsinline={true}
            onReady={() => setReady(true)}

          />
        </div>

        <div className={styles.contentWrapper}>
          <div className='container'>

            <div className={styles.content}>
              <h1 style={{ color: "#fff" }} className='m-auto mb-5 animate__animated animate__fadeInUp'>We work with brands that are ready to <span className={styles.showUp}>sh<img style={{ width: "40px" }} src='/images/tm-logo.gif' />w</span> up.</h1>
              <p style={{ color: "#fff" }} className='animate__animated animate__fadeInUp animate__delay-1s'>Consumers and business customers are on an ever-evolving journey, forging their own paths and doing things their way. For brands, showing up along that journey — especially in the moments that matter — is critical. This means taking an active role in helping people reach their dreams, build their plans, and fulfill their needs. Our people, ideas, tools and grit help brands show up.</p>
              <a href='/contact' className='cta d-lg-none mt-4 animate__fadeInUp'>Contact us</a>
            </div>

          </div>
        </div>
      </div>
      <div id='work' className='animate__animated animate__fadeInUp animate__delay-1s'>
        <BlockMix
          blocksData={pageBlocks?.editorBlocks}
          acfData={pageBlocks?.builder?.sections}
        />
      </div>

      <div style={{ background: "#0c2a31" }} id='about' className='animate__animated animate__fadeInUp animate__delay-1s'>
        <Team />
        <Offices />
        <div className={`container`}>
          <div className='row'>
            <div className={`col-12 col-xl-7 offset-xl-1 `}>
              <div className={styles.text}>
                <h3>This Machine is part of the <a target='_blank' href='/'>Agent3 Group</a>, a global, fast-growing company that specializes in transformative marketing. </h3>
                <h3>Agent3 Group helps clients grow across the entire customer lifecycle, including services for account based marketing and demand generation.</h3>
              </div>

            </div>
          </div>


        </div>

      </div>

    </div>

  );
}

export const getServerSideProps: GetServerSideProps = async () => {
  const apolloClient = initializeApollo();

  const pageBlocks = await apolloClient.query({
    query: getEntityBlocks(),
    variables: {
      id: 'this-machine-2',
      idType: PageIdType.Uri,
      asPreview: false,
    },
  });
  const pageProps = {
    pageBlocks: pageBlocks?.data?.page,
  };

  return await getGlobalData(pageProps, apolloClient);
}